<template>
 <div
  class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md">
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatistics'">
   {{ $t("expertStatisticsNavigation.origins_title") }} {{urlType === 'queue' ? 'PAR FILE' : 'PAR UTILISATEUR'}}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsAnsweredDaily'">
   {{ $t("expertStatisticsNavigation.answeredUnansweredCallsDaily") }} {{urlType === 'queue' ? 'PAR FILE' : 'PAR UTILISATEUR'}}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsAnsweredUnanswered'">
   {{ $t("expertStatisticsNavigation.answeredUnansweredCalls") }} {{urlType === 'queue' ? 'PAR FILE' : 'PAR UTILISATEUR'}}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsAnsweredUnansweredWeekYear'">
   {{ $t("expertStatisticsNavigation.answeredUnansweredCalls") }} -
   <span class="text-lg italic">
    {{ $t("answeredUnansweredCallsWeekYear") }}
   </span>
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsAnsweredUnansweredWeekDay'">
   {{ $t("expertStatisticsNavigation.answeredUnansweredCalls") }} -
   <span class="text-lg italic">
    {{ $t("answeredUnansweredCallsWeekDay") }}
   </span>
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOriginDetail'">
   {{ $t("expertStatisticsNavigation.originsTop10") }} {{urlType === 'queue' ? 'PAR FILE' : 'PAR UTILISATEUR'}}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsReportList'">
   {{ $t("expertStatisticsNavigation.reportList") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsCallerNumbers'">
   {{ $t("expertStatisticsNavigation.callerNumbers") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsDIDReport'">
   Rapport {{ $t("didReport") }} 
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsThirdPartyNumber'">
   {{ $t("expertStatisticsNavigation.thirdPartyNumber") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOutbound'">
   {{ $t("expertStatisticsNavigation.overview") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOutboundAnswered'">
   {{ $t("expertStatisticsNavigation.answeredUnansweredCalls") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOutboundDuration'">
   {{ $t("expertStatisticsNavigation.durations") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOutboundPbx'">
   {{ $t("expertStatisticsNavigation.pbx") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOutboundTrunk'">
   {{ $t("expertStatisticsNavigation.trunk") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold mb-"
   v-if="this.$route.name === 'ExpertStatisticsQueueDetail'">
   Rapport {{ $t("queueDetail") }}
   <span class="text-xl text-gray-800" v-if="selectedQueue">
    -
    <span v-for="(queue, idx) in selectedQueue" :key="queue">
     {{ queue
     }}<span v-show="idx < selectedQueue.length - 1" class="mr-1">,</span>
    </span>
    <!-- <span v-if="pbxMapData">{{
                pbxMapData.call_queues[selectedQueue].name
              }}</span> -->
   </span>
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsQueueComparator'">
   {{ $t("queueComparison") }}
   <span v-if="compareElement && compareElement.length > 0">
    - {{ compareElement[1] }} {{ $t("compareTo") }}
    {{ compareElement[0] }}
    <!-- <span v-if="pbxMapData">{{
                pbxMapData.call_queues[selectedQueue].name
              }}</span> -->
   </span>
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsTRTC'">
   {{ $t("didCallers") }}
   <!-- <span v-if="selectedQueue">
              - {{ selectedQueue }}
              
            </span> -->
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="
    this.$route.name === 'ExpertStatisticsGroups' &&
    this.$route.path.includes('queue')
   ">
   {{ $t("groupsQueues") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="
    this.$route.name === 'ExpertStatisticsGroups' &&
    this.$route.path.includes('extension')
   ">
   {{ $t("groupsExtensions") }}
  </h2>
 </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ChevronDownIcon } from "@heroicons/vue/solid";

const account = localStorage.getItem("account");

export default {
 props: [
  "selectHostname",
  "parseDateRange",
  "buttonSelected",
  "parseTimeRange",
  "periodSelector",
  "timeSelected",
  "urlType",
  "pbxMenuType",
  "elementToRemove",
  "elements",
  "buttonTitle",
  "compareElement",
 ],
 components: {
  ChevronDownIcon,
 },
 data() {
  return {
   search: "",
   closeForm: false,
   account,
   enabled: false,
   selectHostName: "",
   selectHostContext: "",
   clearAllElements: false,
   hostsNames: [],
   elementListConcatReduce: [],
   elementGroupsExtensionList: [],
   elementGroupsQueueList: [],
   extensionsList: [],
   queueList: [],
   clearAllElements: true,
   selectAllElements: false,
   selectedAllElements: false,
  };
 },
 methods: {},
 mounted() {},
 computed: {
  ...mapGetters(["buttonSelector", "hostName"]),
 },
 watch: {},
};
</script>

<style scoped>
.route_table_header {
 padding: 10px 40px 10px 40px;
}
.select {
 padding-right: 0.5rem;
}
</style>
