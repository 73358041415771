<template>
 <div
  class="text-center lg:flex lg:justify-start lg:flex-wrap items-center sm:pb-2 space-y-4">
  <SwitchGroup as="div" class="flex items-center text-center gap-2">
   <Switch
    :disabled="this.elements && this.elements.length > 1 ? false : true"
    v-model="enabled"
    :class="[
     this.elements && this.elements.length > 1
      ? `text-sm font-medium text-gray-900 bg-teal`
      : `text-sm text-gray-500 bg-gray-600`,
     'relative flex gap-1 text-center h-7 w-60 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
    ]">
    <span
     v-if="enabled"
     class="text-sm font-bold text-white capitalize px-2 py-0.5"
     >{{ $t("Vue détaillée") }}</span
    >
    <!-- <span class="text-sm text-gray-500">20s</span> -->
    <span
     aria-hidden="true"
     :class="[
      enabled ? 'translate-x-1 ' : 'translate-x-0 w-30',
      'pointer-events-none  h-6 text-center items-center px-3 py-0.5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
     ]">
     <SwitchLabel as="span" class="flex cursor-pointer">
      <span v-if="enabled" class="text-sm font-bold text-gray-700 capitalize">{{
       $t("Vue consolidée")
      }}</span>
      <span v-else class="text-sm font-bold text-gray-700 capitalize">{{
       $t("Vue détaillée")
      }}</span>
      <!-- <span class="text-sm text-gray-500">20s</span> -->
     </SwitchLabel>
    </span>
    <span
     v-if="!enabled"
     class="text-sm font-bold text-white capitalize px-2 py-0.5"
     >{{ $t("Vue consolidée") }}</span
    >
    <!-- <span class="text-sm text-gray-500">20s</span> -->
   </Switch>
  </SwitchGroup>
  <!-- <div
  class="text-center relative z-0 flex flex-wrap justify-between sm:pb-5 sm:pr-0 sm:pl-0 z-10">
  <div class="">
   <span
    v-for="element in elements"
    :key="element"
    class="mx-0.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium body__header--button body__header--button__text">
    {{ element }}
   </span>
  </div>
  <SwitchGroup as="div" class="flex items-center mb-2">
   <Switch
    :disabled="this.elements.length > 1 ? false : true"
    v-model="enabled"
    :class="[
     enabled ? 'encom_primary' : 'bg-gray-200',
     'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 encom_ring_color',
    ]">
    <span
     aria-hidden="true"
     :class="[
      enabled ? 'translate-x-5' : 'translate-x-0',
      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
     ]" />
   </Switch>
   <SwitchLabel as="span" class="ml-3">
    <span
     :class="
      this.elements.length > 1
       ? `text-sm font-medium text-gray-900`
       : `text-sm text-gray-500`
     ">
     {{ $t("dashboards.concatenate") }}</span
    >
   </SwitchLabel>
  </SwitchGroup>
 </div> -->
 </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";

import {
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 Popover,
 PopoverButton,
 PopoverPanel,
 Switch,
 SwitchGroup,
 SwitchLabel,
} from "@headlessui/vue";

const account = localStorage.getItem("account");

export default {
 props: [
  "buttonSelected",
  "concatenateData",
  "elements",
  "urlType",
  "elementsLength",
 ],
 components: {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
 },
 setup() {
  const enabled = ref(false);

  return {
   enabled,
  };
 },
 data() {
  return {
   selections: [],
   account,
   currentYear: "",
   currentMonth: "",
   selectedYear: "",
   date: "",
   day: "",
   elementToRemove: "",
   hour: "",
   isActive: false,
   maxYear: "",
   minYear: "2000",
   month: "",
   selector: "hour",
   selectedMonth: "",
   search: true,
   searchActive: false,
   showCalendar: false,
   showLess: true,
   months: [
    { id: 1, text: "Jan", value: "01" },
    { id: 2, text: "Feb", value: "02" },
    { id: 3, text: "Mar", value: "03" },
    { id: 4, text: "Apr", value: "04" },
    { id: 5, text: "May", value: "05" },
    { id: 6, text: "Jun", value: "06" },
    { id: 7, text: "Jul", value: "07" },
    { id: 8, text: "Aug", value: "08" },
    { id: 9, text: "Sep", value: "09" },
    { id: 10, text: "Oct", value: "10" },
    { id: 11, text: "Nov", value: "11" },
    { id: 12, text: "Dec", value: "12" },
   ],
  };
 },
 methods: {
  testDateSend(dates) {
   console.log(dates);
  },
  elementRemoved(element) {
   this.elementToRemove = element;
   this.$emit("elementToRemove", this.elementToRemove);
  },

  goToDetail(event) {
   this.$router.push(`/expert-statistics/${event.type}/${event.value}`);
  },
  currentMonthAndYearForPbx() {
   let month = new Date().getMonth() + 1;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
  getYear() {
   let year = new Date().getFullYear();
   let month = new Date().getMonth();
   this.maxYear = year;
   this.currentYear = year;
   this.currentMonth = month;
  },
  emitSelectedMonth(year, month, idx, text) {
   this.selectedMonth = idx;
   this.selectedYear = year;
   let buttonSelector = "month";
   let emittedDate = this.currentYear + month;
   this.$emit("selector", emittedDate);
   this.$emit("button-selector", buttonSelector, text);
   this.$store.dispatch("buttonSelector", buttonSelector);
   setTimeout(() => {
    this.isActive = false;
   }, 600);
  },
  nextPage() {
   if (this.currentYear < this.maxYear) {
    this.currentYear = this.currentYear + 1;
   } else {
    this.currentYear = this.currentYear;
   }
  },
  previousPage() {
   if (this.currentYear > this.minYear) {
    this.currentYear = this.currentYear - 1;
   } else {
    this.currentYear = this.currentYear;
   }
  },
  siphenPeriodMonth(period) {
   let month = period.slice(-2);
   let year = period.slice(0, 4);
   return month + " - " + year;
  },
  parseDate(date) {
   let newDate = new Date(date);
   let year = newDate.getFullYear();
   let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
   let day = ("0" + newDate.getDate()).slice(-2);
   let hours = ("0" + newDate.getHours()).slice(-2);
   let minutes = ("0" + newDate.getMinutes()).slice(-2);
   let seconds = ("0" + newDate.getSeconds()).slice(-2);
   this.month = year + month;
   this.day = year + month + day;
   this.hour = hours + minutes;
   let fullDate =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;
   return String(fullDate);
  },
  parseDateRange(date) {
   let newDate = new Date(date);
   let year = newDate.getFullYear();
   let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
   let day = ("0" + newDate.getDate()).slice(-2);
   let fullDay = "";
   fullDay = year + month + day;

   return String(fullDay);
  },
 },
 mounted() {
  this.getYear();
 },
 watch: {
  date: function (val) {
   let dayRange = "";
   let buttonSelector = "";
   if (typeof val === "object" && val !== null) {
    if (Object.keys(val).length > 1) {
     buttonSelector = "custom";
     dayRange =
      this.parseDateRange(val.start) + "-" + this.parseDateRange(val.end);
     this.$emit("selector", dayRange);
     this.$emit("button-selector", buttonSelector);
     this.$store.dispatch("buttonSelector", buttonSelector);
    } else {
     buttonSelector = "day";
     this.parseDate(val);
     this.$emit("selector", this.day);
     this.$emit("button-selector", buttonSelector);
     this.$store.dispatch("buttonSelector", buttonSelector);
    }
   } else {
    this.parseDate(val);
    this.$emit("selector", this.day);
   }
  },
  enabled: function (val) {
   this.$emit("concatenate", val, this.elements);
  },
  pbxElements: function (val) {
   let arr = [];
   if (val) {
    arr = val;
   }
   if (arr.length < 2) {
    this.enabled = false;
   }
  },
  // concatenateData: function(val) {
  //   this.enabled = val;
  // },
  selections: function (val) {
   if (val.length < 2) {
    this.enabled = false;
   }
  },
 },
 computed: {
  ...mapGetters(["buttonSelector", "pbxElements"]),
  monthsForCurrentYear() {
   let months = [];
   let yearSlice = String(new Date().getFullYear());
   let month = new Date().getMonth() + 1;

   for (let index = 1; index <= month; index++) {
    months.push(`${yearSlice}` + `${("0" + String(index)).slice(-2)}`);
   }
   return months;
  },
 },
};
</script>

<style>
.z_index {
 z-index: 10;
}

.call_queue_table_heading {
 padding: 10px 40px 10px 40px;
}
</style>
