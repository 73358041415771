<template>
  <div
    class="bg-white overflow-hidden shadow rounded-lg panel_width mt-1"
    v-if="seriesTotal.length > 0 && labelTotal.length > 0"
  >
    <div class="px-3 py-4 sm:p-4">
      <DonutChart
        :dataSeries="seriesTotal"
        :chartLabel="labelTotal"
        :title="title"
        :subtitle="subtitle"
        :colors="this.colors"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import DonutChart from "../DonutChart.vue";

export default {
  name: "Home",
  props: ["data", "title", "subtitle", "concatenateData"],
  components: {
    DonutChart,
  },
  data() {
    return {
      seriesTotal: [],
      labelTotal: [],
      colors: [],
      pbx3cxData: [],
      callData: [],
      callDataHistory: [],
      callDataParticipants: [],
      callDataSegments: [],
      hostName: "demo-encom.3cx.fr",
      limit: 1,
      pbxExtensions: [],
      pbxQueues: [],
      monthlyDataExtensions: [],
      monthlyDataExtensionsSeries: [],
      monthlyDataExtensionsLabels: [],
      monthlyDataQueuesSeries: [],
      monthlyDataQueuesLabels: [],
    };
  },
  methods: {
    getAndSortData() {
      this.colors = [];
      if (this.data) {
        this.labelTotal = Object.keys(this.data).sort();
        let keys = Object.keys(this.data).sort();
        keys.forEach((element, index) => {
          this.seriesTotal.push(parseInt(this.data[element]["dataObjSerie"]));
          if (this.data[element]["dataObjColor"]) {
            this.colors.push(this.data[element]["dataObjColor"]);
          }
        });
      }
    },
    sortDateTime(data) {
      data.sort(function(a, b) {
        return new Date(a) - new Date(b);
      });
    },
    formatDateToTime(date) {
      if (date) {
        let hours = ("0" + new Date(date).getHours()).slice(-2);
        let minutes = ("0" + new Date(date).getMinutes()).slice(-2);
        let seconds = ("0" + new Date(date).getSeconds()).slice(-2);
        return hours + ":" + minutes + ":" + seconds;
      } else {
        return "null";
      }
    },
  },
  mounted() {
    this.getAndSortData();
  },
  computed: {
    currentMonthAndYearForPbx() {
      let month = new Date().getMonth() + 1;
      let year = new Date().getFullYear();
      return String(year) + String(month);
    },
  },
  watch: {
    limit: function(val) {
      if (val > 0) this.getPBXData();
      else this.limit = 1;
    },
  },
};
</script>

<style></style>
