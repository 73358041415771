<template>
 <ExpertStatisticsTemplate
  :periodSelector="periodSelector"
  @timeSelector="$emit('timeSelector', $event)"
  :elements="extensions"
  :clearTimeSelector="clearTimeSelector"
  @setPeriod="(...args) => $emit('setPeriod', ...args)"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="(activeBanner = true), (key = !key)"
  @successMsg="successMsg = $event"
  @success="isSuccess = $event"
  :urlType="urlType"
  @setLoading="$emit('setLoading', $event)">
  <template v-slot:selector>
   <PbxDashboardSelector
    @concatenate="(concatenateData = $event), $emit('concatenate', $event)"
    :formatDateToTime="this.formatDateToTime()"
    :concatenateData="concatenateData"
    :elements="extensions"
    :elementsLength="extensions.length"
    :urlType="urlType"
    :queryElement="queryElement"
    @selectedDurationMenuItem="selectedDurationMenuItem = $event" />
  </template>
  <template v-slot:content>
   <!-- <PbxHeader
    :selectHostname="selectHostname"
    :buttonSelected="buttonSelected"
    :parseDateRange="parseDateRange"
    :parseTimeRange="parseTimeRange"
    :periodSelector="periodSelector"
    :timeSelected="timeSelected"
    :urlType="urlType"
    :pbxMenuType="pbxMenuType"
    :elementToRemove="elementToRemove"
    :elements="extensions"
    @selector="$emit('update:selector', $event)"
    @button-selector="(...args) => $emit('button-selector', ...args)"
    @action="$emit('action', $event)"
    @concatenate="concatenateData = $event"
    @all-selected="$emit('all-selected', $event)"
    @select-hostname="this.selectHostname = $event"
    @select-hostContext="$emit('select-hostContext')"
    :formatDateToTime="this.formatDateToTime()"
    :concatenateData="concatenateData"
    :elementsLength="extensions.length"
    @elementToRemove="elementToRemove = $event"
    @activeBanner="(activeBanner = true), (key = !key)"
    @successMsg="successMsg = $event"
    @success="isSuccess = $event"
    :selections="extensions" /> -->

   <PbxHeader
    :selectHostname="selectHostname"
    :buttonSelected="buttonSelected"
    :parseDateRange="parseDateRange"
    :parseTimeRange="parseTimeRange"
    :periodSelector="periodSelector"
    :timeSelected="timeSelected"
    :urlType="urlType"
    :pbxMenuType="pbxMenuType"
    :elementToRemove="elementToRemove"
    :elements="extensions"
    @selector="$emit('update:selector', $event)"
    @button-selector="(...args) => $emit('button-selector', ...args)"
    @action="$emit('action', $event)"
    @concatenate="concatenateData = $event"
    @all-selected="$emit('all-selected', $event)"
    @select-hostname="this.selectHostname = $event"
    @select-hostContext="$emit('select-hostContext')"
    :formatDateToTime="this.formatDateToTime()"
    :concatenateData="concatenateData"
    :elementsLength="extensions.length"
    @elementToRemove="elementToRemove = $event"
    @activeBanner="(activeBanner = true), (key = !key)"
    @successMsg="successMsg = $event"
    @success="isSuccess = $event"
    :selections="extensions" />
   <div class="flex gap-2 justify-start py-4">
    <SwitchGroup
     v-if="$route.name === 'ExpertStatisticsAnsweredUnanswered'"
     as="div"
     class="flex items-center text-center gap-2">
     <Switch
      v-model="answered_calls_stats_day"
      :class="[
       answered_calls_stats_day ? 'bg-teal' : 'bg-teal',
       'relative flex gap-1 text-center h-7 w-56 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
      ]">
      <span
       v-if="answered_calls_stats_day"
       class="text-sm font-bold text-white px-2 py-0.5"
       >{{ $t("Vue par heure") }}</span
      >
      <!-- <span class="text-sm text-gray-500">20s</span> -->
      <span
       aria-hidden="true"
       :class="[
        answered_calls_stats_day ? 'translate-x-1 ' : 'translate-x-0 w-30',
        'pointer-events-none  h-6 text-center items-center px-3 py-0.5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
       ]">
       <SwitchLabel as="span" class="flex cursor-pointer">
        <span
         v-if="answered_calls_stats_day"
         class="text-sm font-bold text-gray-700"
         >{{ $t("Vue par jour") }}</span
        >
        <span v-else class="text-sm font-bold text-gray-700">{{
         $t("Vue par heure")
        }}</span>
        <!-- <span class="text-sm text-gray-500">20s</span> -->
       </SwitchLabel>
      </span>
      <span
       v-if="!answered_calls_stats_day"
       class="text-sm font-bold text-white px-2.5 py-0.5"
       >{{ $t("Vue par jour") }}</span
      >
      <!-- <span class="text-sm text-gray-500">20s</span> -->
     </Switch>
    </SwitchGroup>
    <SwitchGroup
     v-if="$route.name === 'ExpertStatistics'"
     as="div"
     class="flex items-center text-center gap-2">
     <Switch
      v-model="origins_calls_stats_default"
      :class="[
       origins_calls_stats_default ? 'bg-teal' : 'bg-teal',
       'relative flex gap-1 text-center h-7 w-36 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
      ]">
      <span
       v-if="origins_calls_stats_default"
       class="text-sm font-bold text-white capitalize px-2 py-0.5"
       >{{ $t("top 10") }}</span
      >
      <!-- <span class="text-sm text-gray-500">20s</span> -->
      <span
       aria-hidden="true"
       :class="[
        origins_calls_stats_default ? 'translate-x-1 ' : 'translate-x-0',
        'pointer-events-none  h-6 text-center items-center px-3 py-0.5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
       ]">
       <SwitchLabel as="span" class="flex cursor-pointer">
        <span
         v-if="origins_calls_stats_default"
         class="text-sm font-bold text-gray-700 capitalize"
         >{{ $t("default") }}</span
        >
        <span v-else class="text-sm font-bold text-gray-700 capitalize">{{
         $t("top 10")
        }}</span>
        <!-- <span class="text-sm text-gray-500">20s</span> -->
       </SwitchLabel>
      </span>
      <span
       v-if="!origins_calls_stats_default"
       class="text-sm font-bold text-white capitalize px-2 py-0.5"
       >{{ $t("default") }}</span
      >
      <!-- <span class="text-sm text-gray-500">20s</span> -->
     </Switch>
    </SwitchGroup>
   </div>
   <div class="mx-5" v-if="this.$route.name !== 'ExpertStatisticsQueueDetail'">
    <div class=" lg:pl-10 lg:pr-10 pt-5">
     <div
      v-if="
       concatenateData &&
       dataColumnChartQuarterHourlyCons &&
       dataColumnChartQuarterHourlyCons[0]
      ">
      <div v-if="!showChartHourly">
       <BasicColumnDashboard
        :answered_calls_stats_day="answered_calls_stats_day"
        @toggleChartHourly="showChartHourly = !showChartHourly"
        v-if="
         dataColumnChartQuarterHourlyCons[0].data &&
         dataColumnChartQuarterHourlyCons[0].labels.length > 0
        "
        :seriesForColumn="dataColumnChartQuarterHourlyCons[0].data"
        :categoriesForColumn="dataColumnChartQuarterHourlyCons[0].labels"
        :colors="colors"
        :showChartHourly="showChartHourly" />
      </div>
      <div
       v-if="
        showChartHourly &&
        dataColumnChartHourlyCons &&
        dataColumnChartHourlyCons[0]
       ">
       <BasicColumnDashboard
        :answered_calls_stats_day="answered_calls_stats_day"
        @toggleChartHourly="showChartHourly = !showChartHourly"
        v-if="
         dataColumnChartHourlyCons[0].data &&
         dataColumnChartHourlyCons[0].labels.length > 0
        "
        :seriesForColumn="dataColumnChartHourlyCons[0].data"
        :categoriesForColumn="dataColumnChartHourlyCons[0].labels"
        :colors="colors"
        :showChartHourly="showChartHourly" />
      </div>
     </div>
     <div v-else>
      <div v-if="dataColumnChartQuarterHourly && !showChartHourly">
       <div v-for="data in dataColumnChartQuarterHourly" :key="data">
        <BasicColumnDashboard
         :answered_calls_stats_day="answered_calls_stats_day"
         @toggleChartHourly="showChartHourly = !showChartHourly"
         :seriesForColumn="data.data"
         :categoriesForColumn="data.labels"
         :colors="colors"
         :title="
          $t('expertStatisticsNavigation.answeredUnansweredCalls') +
          ` - ${data.name}`
         "
         :showChartHourly="showChartHourly" />
       </div>
      </div>
      <div v-if="dataColumnChartHourly && showChartHourly">
       <div v-for="data in dataColumnChartHourly" :key="data">
        <BasicColumnDashboard
         :answered_calls_stats_day="answered_calls_stats_day"
         @toggleChartHourly="showChartHourly = !showChartHourly"
         :seriesForColumn="data.data"
         :categoriesForColumn="data.labels"
         :colors="colors"
         :showChartHourly="showChartHourly"
         :title="
          $t('expertStatisticsNavigation.answeredUnansweredCalls') +
          ` - ${data.name}`
         " />
       </div>
      </div>
     </div>
     <div
      v-if="
       concatenateData && dataSeriesConcatenated && dataSeriesConcatenated[0]
      "
      class="pt-5">
      <div class="lg:grid gap-2 grid-cols-3">
       <DonutsDashboard
        :key="dataSeriesConcatenated[0]"
        v-show="dataSeriesConcatenated[0].data"
        :data="dataSeriesConcatenated[0].data"
        :title="$t('dashboards.stackedColumnCategories.incomingCount')"
        :colors="this.colors"
        :concatenateData="concatenateData" />
      </div>
     </div>
     <div v-else class="pt-5">
      <div class="lg:grid gap-2 grid-cols-3" v-if="dataSeriesCurrentMonth">
       <div v-for="data in dataSeriesCurrentMonth" :key="data">
        <DonutsDashboard
         :data="data.data"
         :title="
          $t('dashboards.stackedColumnCategories.incomingCount') +
          ' - ' +
          `${data.name}`
         "
         :colors="this.colors"
         :name="data.name" />
       </div>
      </div>
     </div>
    </div>
   </div>
  </template>
 </ExpertStatisticsTemplate>
</template>

<script>
import ExpertStatisticsTemplate from "../../views/PBX/ExpertStatisticsTemplate.vue";
// @ is an alias to /src
import axios from "axios";
import DonutsDashboard from "./DonutsDashboard.vue";
import PbxDashboardSelector from "./PbxDashboardSelector.vue";
import StackedBarChart from "../StackedBarChart.vue";
import BasicColumnDashboard from "./BasicColumnDashboard.vue";
import PbxHeader from "./PbxHeader.vue";

import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

const pbxMenuType = "statistics";

export default {
 name: "PbxDashboard",
 props: [
  "buttonSelected",
  "dataColumnChartQuarterHourly",
  "dataColumnChartHourly",
  "dataColumnChartQuarterHourlyCons",
  "dataColumnChartHourlyCons",
  "dataLabelsCurrentMonth",
  "dataSeriesCurrentMonth",
  "dataSeriesStacked",
  "dataSeriesConcatenated",
  "extensions",
  "periodSelector",
  "textMonth",
  "timeSelected",
  "urlType",
 ],
 components: {
  DonutsDashboard,
  PbxDashboardSelector,
  StackedBarChart,
  BasicColumnDashboard,
  PbxHeader,
  ExpertStatisticsTemplate,
  Switch,
  SwitchGroup,
  SwitchLabel,
 },
 data() {
  return {
   origins_calls_stats_default: true,
   answered_calls_stats_day: true,
   elementToRemove: "",
   pbxMenuType,
   showChartHourly: false,
   seriesForColumn: [],
   categoriesForColumn: [],
   elements: [],
   concatenatedChartData: [],
   concatenatedChartDataStacked: [],
   concatenateData: false,
   montlyTotalSeries: [],
   montlyTotalLabels: [],
   totalChartSeries: [],
   totalChartLabels: [],
   pbx3cxData: {},
   callData: [],
   callDataHistory: [],
   callDataParticipants: [],
   callDataSegments: [],
   colors: [
    "#97BAA6",
    "#14233C",
    "#E34B5F",
    "#194E63",
    "#1E7889",
    "#D9B3A3",
    "#EFB0A1",
    "#5B9998",
    "#A5A59D",
    "#CAAB9F",
   ],
   pbxExtensions: [],
   totalExtension: [],
   totalCallQueue: [],
   totalOther: [],
   totalExtensionValue: "",
   pbxQueues: [],
   selectHostname: "",
   stats: [
    {
     name: "Total Extension",
     stat: "1000",
     previousStat: "900",
     change: "10%",
     changeType: "increase",
    },
    {
     name: "Total Callqueue",
     stat: "",
     previousStat: "",
     change: "",
     changeType: "",
    },
    {
     name: "Total Other",
     stat: "",
     previousStat: "",
     change: "",
     changeType: "",
    },
   ],
  };
 },
 methods: {
  setElements(event) {
   this.elements = [];
   for (let index = 0; index < event.length; index++) {
    this.elements.push(event[index].label);
   }
  },

  // concatenateChartData(event) {
  //   if (this.dataSeriesCurrentMonth.length) {
  //     let arrayBeforeConcatenate = [];
  //     for (
  //       let index = 0;
  //       index < this.dataSeriesCurrentMonth.length;
  //       index++
  //     ) {
  //       arrayBeforeConcatenate.push(this.dataSeriesCurrentMonth[index].data);
  //     }
  //     this.concatenatedChartData = this.sumArrays(arrayBeforeConcatenate);
  //   }
  // },
  sumArrays(array) {
   if (array.length > 1) {
    let summedArray = [];
    let summedArrayElement0 = array[0][0];
    let summedArrayElement1 = array[0][1];
    let summedArrayElement2 = array[0][2];
    for (let index = 0; index < array.length - 1; index++) {
     summedArrayElement0 = summedArrayElement0 + array[index + 1][0];
     summedArrayElement1 = summedArrayElement1 + array[index + 1][1];
     summedArrayElement2 = summedArrayElement2 + array[index + 1][2];
    }
    summedArray.splice(0, 0, summedArrayElement0);
    summedArray.splice(1, 0, summedArrayElement1);
    summedArray.splice(2, 0, summedArrayElement2);
    return summedArray;
   }
  },
  sortDateTime(data) {
   data.sort(function (a, b) {
    return new Date(a) - new Date(b);
   });
  },
  formatDateToTime(date) {
   if (date) {
    let hours = ("0" + new Date(date).getHours()).slice(-2);
    let minutes = ("0" + new Date(date).getMinutes()).slice(-2);
    let seconds = ("0" + new Date(date).getSeconds()).slice(-2);
    return hours + ":" + minutes + ":" + seconds;
   } else {
    return "null";
   }
  },
  getDataForBasicColumn(data) {
   if (data) {
    let keys = Object.keys(data);
    let keyNames = [];
    keyNames.push(keys[0], keys[1]);
    let obj = {};
    for (let index = 0; index < keyNames.length; index++) {
     const element = keyNames[index];
     obj["name"] = element;
     obj["data"] = data[element];
     this.seriesForColumn.push(obj);
    }
    this.categoriesForColumn = data.labels;
   }
  },

  async getPbx3cxData() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/pbx3cx/3/origins`
    );
    this.pbx3cxData = res.data;
    let keys = Object.keys(res.data);
    let extensions = [];
    let queues = [];
    for (let index = 0; index < keys.length; index++) {
     if (keys[index].includes("extension")) {
      let spliceKey = keys[index].split(":");
      extensions.push(spliceKey[1]);
     }
     let spliceQueueKey = keys[index].split(":");
     if (spliceQueueKey[0].includes("queue")) {
      let spliceKey = keys[index].split(":");
      queues.push(spliceKey[1]);
     }
    }
    this.pbxExtensions = extensions.filter(function (item, pos, self) {
     return self.indexOf(item) == pos;
    });
    this.pbxQueues = queues.filter(function (item, pos, self) {
     return self.indexOf(item) == pos;
    });
    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getMontlyPbxDataForExtensionOrQueue(numb) {
   let data = await this.getPbx3cxData();
   let keys = Object.keys(data);
   keys.forEach((element, index) => {
    let spliceKey = keys[index].split(":");
    if (keys[index].includes("extension") && spliceKey[2].length === 6) {
     this.totalExtension.push(parseInt(data[keys[index]]));
    }
    let spliceQueueKey = keys[index].split(":");
    if (keys[index].includes("queue") && spliceQueueKey[2].length === 6) {
     this.totalCallQueue.push(parseInt(data[keys[index]]));
    }
    let spliceOtherKey = keys[index].split(":");
    if (keys[index].includes("other") && spliceOtherKey[2].length === 6) {
     this.totalOther.push(parseInt(data[keys[index]]));
    }
   });
   this.getTotal();
   // let montlyTotal = undefined;
   for (let index = 0; index < keys.length; index++) {
    let spliceQueueKey = keys[index].split(":");
    if (
     numb === spliceQueueKey[1] &&
     spliceQueueKey[2] === this.currentMonthAndYearForPbx
    ) {
     this.montlyTotal = data[keys[index]];
    }
   }
  },
  getTotal() {
   const add = arr => arr.reduce((a, b) => a + b, 0);
   this.stats[0].stat = add(this.totalExtension);
   this.stats[1].stat = add(this.totalCallQueue);
   this.stats[2].stat = add(this.totalOther);
   for (let index = 0; index < this.stats.length; index++) {
    this.totalChartSeries.push(this.stats[index].stat);
    this.totalChartLabels.push(this.stats[index].name);
   }
   this.stats[0].previousStat = 0;
   this.stats[1].previousStat = 0;
   this.stats[2].previousStat = 0;
   this.stats[0].change = "0%";
   this.stats[1].change = "0%";
   this.stats[2].change = "0%";
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    if (this.textMonth) {
     fullDay = this.textMonth + " - " + year;
    } else {
     fullDay = month + "/" + year;
    }
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
 },
 mounted() {
  // this.getPbx3cxData();
 },
 computed: {
  currentMonthAndYearForPbx() {
   let month = new Date().getMonth() + 1;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
  splitedChartSeries() {
   var i,
    j,
    temporary,
    chunk = 3;
   for (i = 0, j = this.dataSeriesCurrentMonth.length; i < j; i += chunk) {
    temporary = this.dataSeriesCurrentMonth.slice(i, i + chunk);
   }
   return temporary;
  },
 },

 watch: {
  dataSeriesCurrentMonth: function (val) {
   if (val.length) {
    this.getDataForBasicColumn(val[0]);
   }
  },
  answered_calls_stats_day: function (val) {
   this.$emit("is_daily", val);
  },
  origins_calls_stats_default: function (val) {
   this.$emit("is_default", val);
  },
 },
};
</script>

<style></style>
