<template>
 <div class="bg-white overflow-hidden shadow rounded-lg panel_width mt-1">
  <div v-if="!answered_calls_stats_day">
   <SwitchGroup as="div" class="flex items-center p-2">
    <Switch
     v-model="enabled"
     :class="[
      showChartHourly ? 'bg-indigo-600' : 'bg-gray-200',
      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
     ]">
     <span
      aria-hidden="true"
      :class="[
       showChartHourly ? 'translate-x-5' : 'translate-x-0',
       'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
      ]" />
    </Switch>
    <SwitchLabel as="span" class="ml-3">
     <span class="text-xs font-medium text-gray-700">Données par heure</span>
    </SwitchLabel>
   </SwitchGroup>
   <div class="px-3 py-4 sm:p-4">
    <BasicColumnChart
     :seriesForColumn="this.seriesForColumn"
     :categoriesForColumn="this.categoriesForColumn"
     width="100%"
     height="500"
     :title="title"
     :totalAnswered="this.getAnsweredNumb(this.seriesForColumn)"
     :totalUnanswered="this.getUnansweredNumb(this.seriesForColumn)" />
   </div>
  </div>
  <div
   v-if="answered_calls_stats_day"
   class="px-3 py-4 sm:p-4">
   <BasicColumnChartDaily
    :seriesForColumn="this.seriesForColumn"
    :categoriesForColumn="this.categoriesForColumn"
    width="100%"
    height="500"
    :title="title"
    :totalAnswered="this.getAnsweredNumb(this.seriesForColumn)"
    :totalUnanswered="this.getUnansweredNumb(this.seriesForColumn)" />
  </div>
 </div>
</template>

<script>
import BasicColumnChartDaily from "./BasicColumnChartDaily.vue";
// import BasicColumnChart from "../BasicColumnChart.vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import Loading from "../Loading.vue";

import { defineAsyncComponent } from "vue";

const BasicColumnChart = defineAsyncComponent(() =>
 import("../BasicColumnChart.vue")
);

export default {
 props: [
  "data",
  "title",
  "subtitle",
  "concatenateData",
  "seriesForColumn",
  "seriesForColumnHourly",
  "categoriesForColumn",
  "categoriesForColumnHourly",
  "showChartHourly",
  "answered_calls_stats_day"
 ],
 components: {
  BasicColumnChart,
  Switch,
  SwitchGroup,
  SwitchLabel,
  Loading,
  BasicColumnChartDaily,
 },
 data() {
  return {
   enabled: false,
  };
 },
 methods: {
  getAnsweredNumb(arr) {
   let filteredAnswered = [];
   for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.name === "Répondus") {
     filteredAnswered = element.data;
    }
   }
   if (filteredAnswered.length > 0) {
    return filteredAnswered.reduce((accumulator, curr) => accumulator + curr);
   } else {
    return 0;
   }
  },
  getUnansweredNumb(arr) {
   let filteredUnanswered = [];
   for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.name === "Non répondus") {
     filteredUnanswered = element.data;
    }
   }
   if (filteredUnanswered.length > 0) {
    return filteredUnanswered.reduce((accumulator, curr) => accumulator + curr);
   } else {
    return 0;
   }
  },
 },
 watch: {
  enabled: function () {
   this.$emit("toggleChartHourly");
  },
 },
};
</script>

<style></style>
