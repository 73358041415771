<template>
 <div class="absolute z-10">
  <div class="flex items-center gap-4">
   <div
    class="flex items-center bg-white shadow-md rounded-md border-gray-400 z-10">
    <p class="border-r px-2 py-2">
     {{
      toLocaleDateString(startDate) +
      (selectedPeriod !== "day" ? " - " + toLocaleDateString(endDate) : "")
     }}
    </p>
    <div
     class="px-2 py-2 cursor-pointer bg-gray-400 border-gray-400 text-white hover:bg-blue-400 rounded-r-md">
     <font-awesome-icon icon="calendar" class="flex-shrink-0" />
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import { mapGetters } from "vuex";

const calendarOptions = [
 {
  name: "yesterday",
  value: "yesterday",
  icon: "calendar-day",
 },
 {
  name: "this week",
  value: "currentWeek",
  icon: "calendar-week",
 },
 {
  name: "last week",
  value: "lastWeek",
  icon: "calendar-week",
 },
 {
  name: "this month",
  value: "currentMonth",
  icon: "calendar",
 },
 {
  name: "last month",
  value: "lastMonth",
  icon: "calendar",
 },
 {
  name: "last 3 months",
  value: "last3Months",
  icon: "calendar",
 },
 {
  name: "last 6 months",
  value: "last6Months",
  icon: "calendar",
 },
 {
  name: "personnalisé",
  value: "custom",
  icon: "calendar",
 },
];

export default {
 props: [
  "periodSelector",
  "elements",
  "clearTimeSelector",
  "startDate",
  "endDate",
 ],
 components: {},
 data() {
  return {
   calendarOptions,
   showDatePickers: false,
   customDate: null,
   selectedPeriod: null,
  };
 },
 methods: {
  selectPeriod(value) {
   this.selectedPeriod = value;
  },
  setPeriod(val) {
   let period = "";
   if (val === "currentWeek") {
    period = this.getCurrentWeek();
   } else if (val === "yesterday") {
    period = this.getYesterday();
   } else if (val === "day") {
    period = this.getCurrentDay();
   } else if (val === "lastWeek") {
    period = this.getLastWeek();
   } else if (val === "currentMonth") {
    period = this.getCurrentMonth();
   } else if (val === "lastMonth") {
    period = this.getLastMonth();
   } else if (val === "last3Months") {
    period = this.getLast3Months();
   } else if (val === "last6Months") {
    period = this.getLast6Months();
   } else {
    period = this.customDate;
   }
   this.startDate =
    val === "custom"
     ? this.parseDateDayMonthYearHifen(period.start)
     : period.firstDay;
   this.endDate =
    val === "custom"
     ? this.parseDateDayMonthYearHifen(period.end)
     : period.lastDay;
   this.$store.dispatch("pbxPeriodSelected", val);
   this.$emit("setPeriod", this.startDate, this.endDate);
  },
 },
 watch: {},
 mounted() {},
 computed: {
  ...mapGetters(["pbxPeriodSelected"]),
 },
};
</script>

<style></style>
